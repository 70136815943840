import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Image,
  Stack,
  VisuallyHidden,
  chakra,
  useColorModeValue
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import theme from "./theme";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { FaInstagram, FaFacebook, FaYoutube, FaAmazon, FaSpotify } from 'react-icons/fa';
import { SiApplemusic, SiPandora } from "react-icons/si";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Box textAlign="center" fontSize="xl">
        <Grid minH="70vh" p={3}>
          <VStack spacing={8}>
            <Logo />
            <Text style={{color: "black", fontSize: 25, fontWeight: "bold"}}>THE RIVER - AVAILABLE NOW!</Text>
            <Stack direction='row' marginLeft="20px">
              <SocialButton label={'Spotify'} href={'https://open.spotify.com/album/0vDLw1gRz9jx3GSQ34gLzY?si=sGc3MQUYS6azPC6klPEs2w'}>
                <FaSpotify />
              </SocialButton>
              <SocialButton label={'Amazon'} href={'https://www.amazon.com/music/player/tracks/B0CVVC44QP'}>
                <FaAmazon />
              </SocialButton>
              <SocialButton label={'Apple Music'} href={'https://music.apple.com/us/album/the-river-ep/1731739836'}>
                <SiApplemusic />
              </SocialButton>
              <SocialButton label={'pandora'} href={'https://www.pandora.com/artist/voltmetre/the-river/AL63xP2rhPlqkh2'}>
                <SiPandora />
              </SocialButton>
              <SocialButton label={'Facebook'} href={'https://www.facebook.com/profile.php?id=100088002289328'}>
                <FaFacebook />
              </SocialButton>
              </Stack>
            <Image src="https://i.imgur.com/KzkjIPK.jpeg" />
            <Image src="https://i.imgur.com/inEYPp5.png" />
          </VStack>
        </Grid>

      </Box>
      <Footer />
    </ChakraProvider>
  );
}

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default App;
