import React from "react";
import { Link, Box, Flex, Text, Button, Stack, Image, chakra, useColorModeValue, VisuallyHidden } from "@chakra-ui/react";
import { FaInstagram, FaFacebook, FaYoutube, FaSpotify, FaAmazon } from 'react-icons/fa';
import { SiApplemusic, SiPandora } from "react-icons/si";


// import Logo from "./Logo";

const NavBar = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
        <Stack direction='row' marginLeft="20px">
              <SocialButton label={'Spotify'} href={'https://open.spotify.com/album/0vDLw1gRz9jx3GSQ34gLzY?si=sGc3MQUYS6azPC6klPEs2w'}>
                <FaSpotify />
              </SocialButton>
              <SocialButton label={'Amazon'} href={'https://www.amazon.com/music/player/tracks/B0CVVC44QP'}>
                <FaAmazon />
              </SocialButton>
              <SocialButton label={'apple'} href={'https://music.apple.com/us/album/the-river-ep/1731739836'}>
                <SiApplemusic />
              </SocialButton>
              <SocialButton label={'pandora'} href={'https://www.pandora.com/artist/voltmetre/the-river/AL63xP2rhPlqkh2'}>
                <SiPandora />
              </SocialButton>
              <SocialButton label={'Facebook'} href={'https://www.facebook.com/profile.php?id=100088002289328'}>
                <FaFacebook />
              </SocialButton>
              </Stack>
        {/* <Image width="100px" src={require("../logo2.png")} /> */}
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={true} />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link href={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const SocialButton = ({
    children,
    label,
    href,
  }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };

const MenuLinks = ({ isOpen }) => {
  return (
    <Box
      margin={5}
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      {/* <Stack
        spacing={8}
        align="flex-end"
        marginRight='80px'
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem to="#">Home</MenuItem>
        <MenuItem to="#">Shows </MenuItem>
        <MenuItem to="#">Merch </MenuItem>
      </Stack> */}
      {/* <Text style={{fontWeight: "bold"}}>NEXT SHOW: Voltmetre EP Release Party</Text>
      <Text>The Pearl on Main - Murray, UT</Text>
      <Text>Friday, March 8, 2024 @ 7PM</Text>
      <Link color='teal.500' href='https://www.24tix.com/event/1235202666?fbclid=IwAR0roxs-cHMpEBEPAO2J9jJP3CFt6lM4zJm7zJDliJeJWuim8dtZ7JsBCeE' isExternal>
  Get tickets here!
</Link> */}
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["#454d57", "#454d57", "#454d57", "#454d57"]}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default NavBar;