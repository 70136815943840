import {
    Box,
    chakra,
    Container,
    Link,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
    Image
  } from '@chakra-ui/react';
  import { FaInstagram, FaFacebook, FaYoutube, FaAmazon, FaSpotify } from 'react-icons/fa';
  import { ReactNode } from 'react';
  import { SiApplemusic, SiPandora } from "react-icons/si";

  
  const Logo = (props: any) => {
    return (
      <Image width={{ base: '50px', lg: '150px' }} src={require("../logo.png")} />
    );
  };
  
  const SocialButton = ({
    children,
    label,
    href,
  }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  export default function SmallCentered() {
    return (
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          spacing={4}
          justify={'center'}
          align={'center'}>
          <Logo />
          {/* <Stack direction={'row'} spacing={6}>
            <Link href={'#'}>Home</Link>
            <Link href={'#'}>Shows</Link>
            <Link href={'#'}>Merch</Link>
          </Stack> */}
        </Container>
  
        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}>
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}>
            <Text>© 2024 Voltmetre. All rights reserved</Text>
            <Stack direction={'row'} spacing={6}>
              <SocialButton label={'Spotify'} href={'https://open.spotify.com/album/0vDLw1gRz9jx3GSQ34gLzY?si=sGc3MQUYS6azPC6klPEs2w'}>
                <FaSpotify />
              </SocialButton>
              <SocialButton label={'Amazon'} href={'https://www.amazon.com/music/player/tracks/B0CVVC44QP'}>
                <FaAmazon />
              </SocialButton>
              <SocialButton label={'apple'} href={'https://music.apple.com/us/album/the-river-ep/1731739836'}>
                <SiApplemusic />
              </SocialButton>
              <SocialButton label={'pandora'} href={'https://www.pandora.com/artist/voltmetre/the-river/AL63xP2rhPlqkh2'}>
                <SiPandora />
              </SocialButton>
              <SocialButton label={'Facebook'} href={'https://www.facebook.com/profile.php?id=100088002289328'}>
                <FaFacebook />
              </SocialButton>
            </Stack>
          </Container>
        </Box>
      </Box>
    );
  }