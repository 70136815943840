import React from 'react';
import { Image, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import logo from './logo.svg';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  25% { transform: scale(2) rotate(0); }
  50% { transform: scale(2) rotate(360deg); }
  100% { transform: scale(1) rotate(0); }
`;

const animation = `${animationKeyframes} 2s ease-in-out`;

export const Logo = props => {
  const prefersReducedMotion = usePrefersReducedMotion();

  return <Image width={{ base: '420px', md: '550px', lg: '700px' }} src={require("./logo.png")} {...props}  />;
};
